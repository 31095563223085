import { MainNavigation } from '@/components/MainNavigation'
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { Switch } from '@/components/ui/switch'
import { useNavigate } from 'react-router-dom'
import { PATH_NAME } from '@/routes/paths'
import { useCurrentUser } from '@/hooks/useCurrentUser'

const CustomizeMealsPage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  if (!currentUser) return null

  return (
    <>
      {/* <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div> */}
      <div className="mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <div className={`px-6 pt-6`}>
          <div className="mb-8 flex items-center space-x-4 align-middle">
            <ArrowLeftIcon width={20} height={20} onClick={() => navigate(PATH_NAME.SETTINGS)} />
            <h1 className="text-xl font-bold">Customize meals</h1>
          </div>

          {currentUser.userMeals.map((meal) => (
            <div key={meal.id} className="flex items-center justify-between py-2">
              {meal.name}
              <Switch checked={meal.enabled} />
            </div>
          ))}
        </div>
      </div>
      <MainNavigation />
    </>
  )
}

export default CustomizeMealsPage
