import { MainNavigation } from '@/components/MainNavigation'
import { Button } from '@/components/ui/button'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { PATH_NAME } from '@/routes/paths'
import { signOut } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'

const SettingsPage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  return (
    <>
      {/* <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div> */}
      <div className="mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <div className={`space-y-8 px-6 pt-6`}>
          <h1>Settings</h1>

          <div>
            <h2 className="mb-2">Account</h2>
            <div className="flex flex-col items-start space-y-2 rounded-md border p-3">
              <button onClick={() => navigate(PATH_NAME.SETTINGS_PROFILE)}>Profile</button>
              <button>Subscription</button>
            </div>
          </div>

          <div>
            <h2 className="mb-2">Preferences</h2>
            <div className="flex flex-col items-start space-y-2 rounded-md border p-3">
              <button onClick={() => navigate(PATH_NAME.SETTINGS_MACRO_TARGETS)}>Macro targets</button>
              <button onClick={() => navigate(PATH_NAME.SETTINGS_CUSTOMIZE_MEALS)}>Customize meals</button>
              <button
                onClick={() => navigate(PATH_NAME.SETTINGS_CALORIE_PLANNER)}
                disabled={currentUser ? currentUser.userMacroTargets.length === 0 : true}
              >
                Calorie planner
              </button>
              <button>Display preferences</button>
            </div>
          </div>

          <div>
            <h2 className="mb-2">Help & info</h2>
            <div className="flex flex-col items-start space-y-2 rounded-md border p-3">
              <button>Community</button>
              <button>Support</button>
              <button>About</button>
            </div>
          </div>

          <div className="flex space-x-4">
            <Button
              onClick={() => {
                localStorage.removeItem('userAttributes')
                window.location.reload()
              }}
              variant={'outline'}
            >
              Reset local data
            </Button>
            <Button onClick={() => signOut()}>Sign Out</Button>
          </div>
        </div>
      </div>

      <MainNavigation />
    </>
  )
}

export default SettingsPage
