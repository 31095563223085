import { MainNavigation } from '@/components/MainNavigation'
import { ArrowLeftIcon } from '@radix-ui/react-icons'

import { useNavigate } from 'react-router-dom'
import { PATH_NAME } from '@/routes/paths'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import useSyncUserCaloriePlanner from '@/hooks/useSyncUserCaloriePlanner'

import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts'

import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { createUserCaloriePlanner, updateUserCaloriePlanner } from '@/services/aws'

const CaloriePlannerPage: React.FC = () => {
  const [isDataValid, setIfDataValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const currentUser = useCurrentUser()
  const syncUserCaloriePlanner = useSyncUserCaloriePlanner()

  const [weekTargets, setWeekTargets] = useState([
    { id: '', day: 'M', dayOfTheWeek: 'Monday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'T', dayOfTheWeek: 'Tuesday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'W', dayOfTheWeek: 'Wednesday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'T', dayOfTheWeek: 'Thursday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'F', dayOfTheWeek: 'Friday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'S', dayOfTheWeek: 'Saturday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 },
    { id: '', day: 'S', dayOfTheWeek: 'Sunday', macroTargetId: '', calories: 0, protein: 0, carbs: 0, fat: 0 }
  ])
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser && currentUser.userCaloriePlanner.length > 0) {
      const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 } as const

      const sortedWeekTargets = currentUser.userCaloriePlanner
        .map((cp) => ({
          id: cp.id,
          day: cp.dayOfTheWeek.charAt(0),
          dayOfTheWeek: cp.dayOfTheWeek,
          macroTargetId: cp.macroTargetId ? cp.macroTargetId : '',
          calories: cp.calories,
          protein: cp.protein,
          carbs: cp.carbs,
          fat: cp.fat
        }))
        .sort((a, b) => order[a.dayOfTheWeek as keyof typeof order] - order[b.dayOfTheWeek as keyof typeof order])

      setWeekTargets(sortedWeekTargets)
    }
  }, [currentUser])

  useEffect(() => {
    setIfDataValid(weekTargets.filter((target) => target.calories === 0).length === 0)
  }, [weekTargets])

  if (!currentUser) return null

  const onSave = async () => {
    if (isDataValid) {
      setLoading(true)

      if (weekTargets.every((t) => t.id === '')) {
        await createUserCaloriePlanner(weekTargets)
          .then((weeklyTargets) => {
            syncUserCaloriePlanner(weeklyTargets)
            setWeekTargets(
              weeklyTargets.map((t) => ({
                ...t,
                id: t.id,
                day: t.dayOfTheWeek.charAt(0),
                macroTargetId: t.macroTargetId ? t.macroTargetId : ''
              }))
            )
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        await Promise.all(
          weekTargets.map((target) => {
            const record = updateUserCaloriePlanner(target)
            return record
          })
        )
          .then((weeklyTargets) => {
            syncUserCaloriePlanner(weeklyTargets)
            setWeekTargets(
              weeklyTargets.map((t) => ({
                ...t,
                id: t.id,
                day: t.dayOfTheWeek.charAt(0),
                macroTargetId: t.macroTargetId ? t.macroTargetId : ''
              }))
            )
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  console.log(loading)

  return (
    <>
      {/* <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div> */}
      <div className="mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <div className={`px-6 pt-6`}>
          <div className="mb-8 flex justify-between">
            <div className="flex items-center space-x-4 align-middle">
              <ArrowLeftIcon width={20} height={20} onClick={() => navigate(PATH_NAME.SETTINGS)} />
              <h1 className="text-xl font-bold">Calorie Planner</h1>
            </div>
            <Button disabled={!isDataValid} variant={'outline'} onClick={onSave}>
              Save
            </Button>
          </div>

          <ChartContainer config={{ protein: {}, carbs: {}, fat: {} }} className="h-[220px] w-full">
            <BarChart accessibilityLayer data={weekTargets} margin={{ top: 0, right: 0, left: 0, bottom: 10 }}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="day"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={2} textAnchor="middle">
                      {payload.value}
                    </text>
                    <text x={0} y={10} dy={8} textAnchor="middle">
                      {weekTargets[payload.index].calories}
                    </text>
                  </g>
                )}
              />
              <ChartTooltip content={<ChartTooltipContent hideLabel />} />
              <Bar dataKey="protein" stackId="a" fill="#93c5fd" radius={[0, 0, 4, 4]}>
                <LabelList
                  dataKey="protein"
                  position="center"
                  fill="#111827"
                  formatter={(value: number) => `${value}p`}
                />
              </Bar>
              <Bar dataKey="carbs" stackId="a" fill="#fdba74" radius={[0, 0, 0, 0]}>
                <LabelList
                  dataKey="carbs"
                  position="center"
                  fill="#111827"
                  formatter={(value: number) => `${value}c`}
                />
              </Bar>
              <Bar dataKey="fat" stackId="a" fill="#86efac" radius={[4, 4, 0, 0]}>
                <LabelList dataKey="fat" position="center" fill="#111827" formatter={(value: number) => `${value}f`} />
              </Bar>
            </BarChart>
          </ChartContainer>

          <div className="mt-8 space-y-2">
            {weekTargets.map((dayTargets, i) => {
              return (
                <div key={`${dayTargets.dayOfTheWeek}-${i}`} className="flex items-center justify-between">
                  <span>{dayTargets.dayOfTheWeek}</span>
                  <span className="w-48">
                    <Select
                      value={dayTargets.macroTargetId}
                      onValueChange={(id) => {
                        console.log(id)
                        setWeekTargets((prev) => {
                          const updatedTargets = [...prev]
                          const selectedMacro = currentUser.userMacroTargets.find(
                            (macroTarget) => macroTarget.id === id
                          )
                          if (selectedMacro) {
                            updatedTargets[i] = {
                              ...updatedTargets[i],
                              macroTargetId: selectedMacro.id,
                              calories: selectedMacro.calories,
                              protein: selectedMacro.protein,
                              carbs: selectedMacro.carbs,
                              fat: selectedMacro.fat
                            }
                          }
                          return updatedTargets
                        })
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>

                      <SelectContent>
                        {currentUser.userMacroTargets.map((macroTarget) => (
                          <SelectItem value={macroTarget.id} key={macroTarget.id}>
                            {macroTarget.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <MainNavigation />
    </>
  )
}

export default CaloriePlannerPage
