import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Cross1Icon } from '@radix-ui/react-icons'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { MainNavigation } from '@/components/MainNavigation'
import { useNavigate } from 'react-router-dom'
import useCreateMyUserFood from '@/hooks/useCreateMyUserFood'
import { useCurrentUser } from '@/hooks/useCurrentUser'

const formSchema = z.object({
  name: z.string().min(2).max(150),
  brand: z.string().optional(),
  volume: z.coerce.number().min(0.1),
  unitId: z.string(),
  calories: z.coerce.number().min(0),
  protein: z.coerce.number().min(0),
  carbs: z.coerce.number().min(0),
  fat: z.coerce.number().min(0)
})

function NewFoodPage() {
  const navigate = useNavigate()
  const { createNewFood, loading } = useCreateMyUserFood()
  const currentUser = useCurrentUser()

  if (!currentUser) {
    throw new Error('no current user')
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      brand: '',
      volume: 0,
      unitId: currentUser.units[0].id,
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0
    }
  })

  function onSubmit(formData: z.infer<typeof formSchema>) {
    createNewFood({
      ...formData,
      id: '',
      searchName: formData.name.toLowerCase(),
      brand: formData.brand ? formData.brand : null,
      unit: { id: formData.unitId, label: '', name: '' }
    }).then(() => navigate(-1))
  }

  return (
    <>
      <div className="mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between border-b-2 p-6">
              <Button
                disabled={loading}
                variant={'ghost'}
                size={'icon'}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  navigate(-1)
                }}
              >
                <Cross1Icon width={20} height={20} />
              </Button>
              <span>Create food</span>
              <Button type="submit" variant={'outline'} disabled={!form.formState.isValid || loading}>
                Create
              </Button>
            </div>
            <div className="space-y-4 p-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Brand (optional)" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex space-x-4">
                <FormField
                  control={form.control}
                  name="volume"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Volume"
                          className="w-24"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="unitId"
                  render={({ field }) => (
                    <FormItem className="flex-grow">
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {currentUser.units.map((unit) => (
                            <SelectItem value={unit.id} key={unit.id}>
                              {unit.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="calories"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                    <FormLabel>Calories</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-28"
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="protein"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                    <FormLabel>Protein (g)</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-28"
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="carbs"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                    <FormLabel>Carbs (g)</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-28"
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fat"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                    <FormLabel>Fat (g)</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-28"
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>

      <MainNavigation />
    </>
  )
}

export default NewFoodPage
