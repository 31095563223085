import { Suspense } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import HomePage from '@/pages/HomePage'
import SettingsPage from '@/pages/SettingsPage'
import { PATH_NAME } from './paths'
import CustomizeMealsPage from '@/pages/settings/CustomizeMealsPage'
import MacroTargetsPage from '@/pages/settings/MacroTargetsPage'
import MealPage from '@/pages/MealPage'
import ProfilePage from '@/pages/ProfilePage'
import NewFoodPage from '@/pages/NewFoodPage'
import { formatISO } from 'date-fns'
import { ErrorBoundary } from './ErrorBoundary'
import CaloriePlannerPage from '@/pages/settings/CaloriePlannerPage'
import AdminDashboardPage from '@/pages/admin'

const INTERNAL_ROUTES: RouteObject[] = [
  {
    path: PATH_NAME.HOME,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HomePage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS_PROFILE,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ProfilePage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS_MACRO_TARGETS,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MacroTargetsPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS_CALORIE_PLANNER,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CaloriePlannerPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS_CUSTOMIZE_MEALS,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomizeMealsPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.MEAL,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MealPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.NEW_FOOD,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NewFoodPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SettingsPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.ADMIN_DASHBOARD,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AdminDashboardPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.TERMS,
    errorElement: <ErrorBoundary />,
    element: <div>Terms</div>
  },
  {
    path: PATH_NAME.PRIVACY,
    errorElement: <ErrorBoundary />,
    element: <div>Privacy</div>
  },
  {
    path: '/',
    element: <Navigate to={`/${formatISO(new Date(), { representation: 'date' })}`} replace />
  }
]

export { INTERNAL_ROUTES }
