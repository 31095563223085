import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { INTERNAL_ROUTES } from './routes/internalRoutes.tsx'
import { ThemeProvider } from './components/ui/theme-provider.tsx'

const router = createBrowserRouter(INTERNAL_ROUTES)

export function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <main className="relative overflow-hidden text-white">
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
      </main>
    </ThemeProvider>
  )
}
