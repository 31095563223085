import { Button } from './ui/button'
import { ArrowRightIcon, Cross1Icon, DotsHorizontalIcon, HeartIcon, TrashIcon } from '@radix-ui/react-icons'
import { Input } from './ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { client } from '@/lib/utils'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Food } from '@/services/aws'
import { formatISO, parseISO } from 'date-fns'
import { calculateMacrosPerVolume } from '@/helpers/calculateMacrosPerVolume'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { PageSpinner } from './ui/pageSpinner'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from './ui/drawer'

export function LogFoodModal({
  food,
  volume,
  logId,
  mealId,
  onClose,
  action
}: {
  food: Food
  logId?: string
  volume?: number
  mealId: string
  onClose: (refetch: boolean) => void
  action: 'create' | 'update'
}) {
  const [consumedVolume, setConsumedVolume] = useState(action === 'create' ? food.volume : volume!)
  const { date } = useParams()
  const currentUser = useCurrentUser()

  if (!date) {
    throw new Error(`no date`)
  }

  console.log('hey LogFoodModal')

  const [macronutrients, setMacronutrients] = useState(() => {
    const calculatedMacros = calculateMacrosPerVolume({ food, volume: consumedVolume })
    return {
      calories: calculatedMacros.calories,
      protein: calculatedMacros.protein,
      carbs: calculatedMacros.carbs,
      fat: calculatedMacros.fat
    }
  })

  const onChangeVolume = (volume: string) => {
    const newVolume = Number(volume)
    const calculatedMacros = calculateMacrosPerVolume({ food, volume: newVolume })

    setConsumedVolume(newVolume)
    setMacronutrients({
      calories: calculatedMacros.calories,
      protein: calculatedMacros.protein,
      carbs: calculatedMacros.carbs,
      fat: calculatedMacros.fat
    })
  }

  const onAddFood = async () => {
    await client.models.Log.create({
      volume: consumedVolume,
      date: formatISO(parseISO(date)),
      foodId: food.id,
      mealId: mealId
    }).finally(() => onClose(true))
  }

  const onUpdateFood = async () => {
    await client.models.Log.update({
      id: logId!,
      volume: consumedVolume
    }).finally(() => onClose(true))
  }

  const onDeleteFoodFromMeal = async () => {
    await client.models.Log.delete({
      id: logId!
    }).finally(() => onClose(true))
  }

  if (!currentUser) return <PageSpinner />

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 translate-x-0 translate-y-0 bg-gray-900">
      <Drawer>
        <div className="flex items-center justify-between p-6">
          <Button variant={'ghost'} size={'icon'} onClick={() => onClose(false)}>
            <Cross1Icon width={20} height={20} />
          </Button>
          <span>{currentUser.userMeals.filter((meal) => meal.id === mealId)[0].name}</span>

          <DrawerTrigger className="flex h-9 w-9 items-center justify-center">
            <DotsHorizontalIcon width={20} height={20} />
          </DrawerTrigger>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <div>{food.name}</div>
            <div className="text-sm text-gray-400">{food.brand}</div>
          </div>
          <div className="mb-6 flex w-full divide-x-2 rounded-md border p-2">
            <span className="grow pr-3 text-center">
              <p>{macronutrients.calories}</p>
              <span className="text-nowrap text-xs text-gray-400">Cal</span>
            </span>
            <span className="grow px-3 text-center">
              <p>{macronutrients.protein}</p>
              <span className="text-nowrap text-xs text-gray-400">Protein (g)</span>
            </span>
            <span className="grow px-3 text-center">
              <p>{macronutrients.carbs}</p>
              <span className="text-nowrap text-xs text-gray-400">Carbs (g)</span>
            </span>
            <span className="grow pl-3 text-center">
              <p>{macronutrients.fat}</p>
              <span className="text-nowrap text-xs text-gray-400">Fat (g)</span>
            </span>
          </div>

          <div className="mb-6 flex space-x-4">
            <Input
              defaultValue={consumedVolume}
              onChange={(e) => onChangeVolume(e.currentTarget.value)}
              placeholder="Volume"
              className="w-24"
              type="number"
              onFocus={(e) => e.target.select()}
              onContextMenu={(e) => e.preventDefault()}
            />

            <Select value={food.unit.id} disabled>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value={food.unit.id} key={food.unit.id}>
                  {food.unit.label}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {action === 'create' && (
            <Button onClick={onAddFood} className="w-full">
              Add food
            </Button>
          )}

          {action === 'update' && (
            <Button onClick={onUpdateFood} className="w-full">
              Update
            </Button>
          )}
        </div>

        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle hidden>Are you absolutely sure?</DrawerTitle>
            <DrawerDescription className="flex flex-col space-y-4 pb-8 text-base text-white">
              {action === 'update' && (
                <>
                  <DrawerClose className="flex items-center space-x-2 text-gray-400" disabled>
                    <ArrowRightIcon />
                    <span>Go to food</span>
                  </DrawerClose>
                  <DrawerClose className="flex items-center space-x-2 text-gray-400" disabled>
                    <HeartIcon />
                    <span>Add to favorites</span>
                  </DrawerClose>
                  <DrawerClose className="flex items-center space-x-2" onClick={onDeleteFoodFromMeal}>
                    <TrashIcon />
                    <span>Delete item from meal</span>
                  </DrawerClose>
                </>
              )}
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
