import { PATH_NAME } from '@/routes/paths'
import { HamburgerMenuIcon, HomeIcon, ClipboardIcon } from '@radix-ui/react-icons'
import { formatISO } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'

export function MainNavigation() {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={`fixed bottom-0 left-0 right-0 flex h-20 justify-between bg-gray-900 p-6 text-gray-400`}>
      <button
        onClick={() => navigate(`/${formatISO(new Date(), { representation: 'date' })}`)}
        className={location.pathname === '/' ? 'text-white' : ''}
      >
        <HomeIcon width={26} height={26} />
      </button>
      <button>
        <ClipboardIcon width={26} height={26} />
      </button>
      <button
        onClick={() => navigate(PATH_NAME.SETTINGS)}
        className={location.pathname.includes(PATH_NAME.SETTINGS) ? 'text-white' : ''}
      >
        <HamburgerMenuIcon width={26} height={26} />
      </button>
    </div>
  )
}
