import {
  getUnits,
  getUserCaloriePlanner,
  getUserMacroTargets,
  getUserMeals,
  MacroTarget,
  Unit,
  CaloriePlanner,
  Meal
} from '@/services/aws'
import { Authenticator } from '@aws-amplify/ui-react'
import { fetchUserAttributes, FetchUserAttributesOutput, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { createContext, useEffect, useState } from 'react'

enum UserGroup {
  User = 'User',
  Admin = 'Admin'
}

type UserAttributesType = FetchUserAttributesOutput & {
  userGroups: UserGroup[]
  userMeals: Meal[]
  units: Unit[]
  userMacroTargets: MacroTarget[]
  userCaloriePlanner: CaloriePlanner[]
}

export const AuthContext = createContext<{
  uId: string
  email: string
  firstName: string
  lastName: string
  birthdate: string
  userGroups: UserGroup[]
  userMeals: Meal[]
  userMacroTargets: MacroTarget[]
  userCaloriePlanner: CaloriePlanner[]
  units: Unit[]
} | null>(null)

export function AuthContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [userAttributes, setUserAttributes] = useState<UserAttributesType>()

  const getUserAttributes = async () => {
    const storedUserAttributes = localStorage.getItem('userAttributes')
    if (storedUserAttributes) {
      setUserAttributes(JSON.parse(storedUserAttributes))
      return
    }

    await getCurrentUser()

    try {
      const session = await fetchAuthSession()
      const fetchedUserAttributes = await fetchUserAttributes()

      const tokens = session.tokens
      if (!tokens) return

      const userGroups: UserGroup[] = tokens.accessToken.payload['cognito:groups']
        ? (tokens.accessToken.payload['cognito:groups'] as UserGroup[])
        : []

      console.log(userGroups)

      const userMeals = await getUserMeals(fetchedUserAttributes.sub!)
      const userMacroTargets = await getUserMacroTargets()
      const userCaloriePlanner = await getUserCaloriePlanner()
      const units = await getUnits()

      const userAttributesWithGroups = {
        ...fetchedUserAttributes,
        userMeals,
        userMacroTargets,
        userCaloriePlanner,
        units,
        userGroups
      } as UserAttributesType

      setUserAttributes(userAttributesWithGroups)

      localStorage.setItem('userAttributes', JSON.stringify(userAttributesWithGroups))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const storedUserAttributes = localStorage.getItem('userAttributes')
    async function authSession() {
      const session = await fetchAuthSession()
      if (session.userSub) {
        getUserAttributes()
      }
    }
    if (!storedUserAttributes) {
      authSession()
    } else {
      setUserAttributes(JSON.parse(storedUserAttributes))
    }

    // Subscribe to auth events
    Hub.listen('auth', ({ payload }) => {
      console.log(`A new auth event has happened: ${payload.event}`)
      switch (payload.event) {
        case 'signedIn':
          getUserAttributes()
          break
        case 'signedOut':
          localStorage.removeItem('userAttributes')
          break
        default:
          break
      }
    })
  }, [])

  const value = userAttributes
    ? {
        uId: userAttributes.sub!,
        email: userAttributes.email!,
        firstName: userAttributes.given_name!,
        lastName: userAttributes.family_name!,
        birthdate: userAttributes.birthdate!,
        userGroups: userAttributes.userGroups!,
        userMeals: userAttributes.userMeals!,
        userMacroTargets: userAttributes.userMacroTargets!,
        userCaloriePlanner: userAttributes.userCaloriePlanner!,
        units: userAttributes.units!
      }
    : null

  return (
    <AuthContext.Provider value={value}>
      <Authenticator socialProviders={import.meta.env.MODE === 'development' ? ['google'] : []}>
        {children}
      </Authenticator>
    </AuthContext.Provider>
  )
}
