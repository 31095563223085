import { MainNavigation } from '@/components/MainNavigation'
import { ArrowLeftIcon, Cross1Icon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import { PATH_NAME } from '@/routes/paths'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { Button } from '@/components/ui/button'
import { nanoid } from 'nanoid'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useEffect, useState } from 'react'
import { Label, Pie, PieChart } from 'recharts'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { createMacroTarget, deleteUserMacroTarget, MacroTarget, updateMacroTarget } from '@/services/aws'
import useSyncUserMacroTargets from '@/hooks/useSyncUserMacroTargets'

const MacroTargetsPage: React.FC = () => {
  const [createMacroTargetModal, setCreateMacroTargetModal] = useState(false)
  const [editMacroTargetModal, setEditMacroTargetModal] = useState<MacroTarget | null>(null)
  const [loading, setLoading] = useState(false)
  const syncUserMacroTargets = useSyncUserMacroTargets()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  if (!currentUser) return null

  async function onSubmitMacroTarget(payload: FormData) {
    setLoading(true)
    await createMacroTarget(payload)
      .then((res) => {
        if (res) {
          syncUserMacroTargets(res, 'create')
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false)
        setCreateMacroTargetModal(false)
      })
  }

  async function onEditMacroTarget(payload: FormData) {
    setLoading(true)

    await updateMacroTarget(payload)
      .then((res) => {
        if (res) {
          syncUserMacroTargets(res, 'update')
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false)
        setEditMacroTargetModal(null)
      })
  }

  async function onDeleteMacroTarget(macroTargetId: string) {
    setLoading(true)

    await deleteUserMacroTarget(macroTargetId)
      .then((res) => {
        if (res) {
          syncUserMacroTargets(res, 'delete')
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false)
        setEditMacroTargetModal(null)
      })
  }

  return (
    <>
      <div className="relative mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <div className={`px-6 pt-6`}>
          <div className="mb-8 flex items-center justify-between align-middle">
            <span className="flex items-center space-x-4">
              <ArrowLeftIcon width={20} height={20} onClick={() => navigate(PATH_NAME.SETTINGS)} />
              <h1 className="text-xl font-bold">Macro Targets</h1>
            </span>
            <Button variant={'outline'} onClick={() => setCreateMacroTargetModal(true)}>
              New
            </Button>
          </div>

          <div className="space-y-4">
            {currentUser.userMacroTargets.map((macroTarget) => (
              <div key={macroTarget.id} className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span>{macroTarget.name}</span>
                  <span className="text-sm text-gray-400">
                    {`${macroTarget.calories} Cal, ${macroTarget.protein}p, ${macroTarget.carbs}c, ${macroTarget.fat}f`}
                  </span>
                </div>
                <Button variant={'ghost'} size={'icon'} onClick={() => setEditMacroTargetModal(macroTarget)}>
                  <Pencil1Icon width={20} height={20} />
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {createMacroTargetModal && (
        <MacroTargetModal
          title="New macro target"
          ctaLabel="Create"
          loading={loading}
          onSubmit={onSubmitMacroTarget}
          onClose={() => setCreateMacroTargetModal(false)}
        />
      )}

      {editMacroTargetModal && (
        <MacroTargetModal
          title="Edit macro target"
          ctaLabel="Update"
          macroTarget={editMacroTargetModal}
          onDeleteMacroTarget={onDeleteMacroTarget}
          loading={loading}
          onSubmit={onEditMacroTarget}
          onClose={() => setEditMacroTargetModal(null)}
        />
      )}
      <MainNavigation />
    </>
  )
}

const formSchema = z.object({
  id: z.string(),
  name: z.string().min(2).max(50),
  calories: z.coerce.number().min(1),
  protein: z.coerce.number().min(1),
  carbs: z.coerce.number().min(1),
  fat: z.coerce.number().min(1)
})

type FormData = z.infer<typeof formSchema>

function MacroTargetModal({
  title,
  ctaLabel,
  macroTarget,
  onSubmit,
  onDeleteMacroTarget,
  onClose,
  loading
}: {
  title: string
  ctaLabel: string
  macroTarget?: MacroTarget
  onSubmit: (formData: FormData) => void
  onDeleteMacroTarget?: (macroTargetId: string) => void
  onClose: () => void
  loading: boolean
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: macroTarget ? macroTarget.id : nanoid(),
      name: macroTarget ? macroTarget.name : '',
      calories: macroTarget ? macroTarget.calories : 0,
      protein: macroTarget ? macroTarget.protein : 0,
      carbs: macroTarget ? macroTarget.carbs : 0,
      fat: macroTarget ? macroTarget.fat : 0
    }
  })

  const { control, setValue } = form

  const protein = useWatch({ control, name: 'protein' })
  const carbs = useWatch({ control, name: 'carbs' })
  const fat = useWatch({ control, name: 'fat' })
  const totalCalories = useWatch({ control, name: 'calories' })

  useEffect(() => {
    const calculatedCalories = protein * 4 + carbs * 4 + fat * 9
    setValue('calories', calculatedCalories)
  }, [protein, carbs, fat, setValue])

  const chartData = [
    { macros: 'protein', value: Number(protein), fill: '#93c5fd' },
    { macros: 'carbs', value: Number(carbs), fill: '#fdba74' },
    { macros: 'fat', value: Number(fat), fill: '#86efac' }
  ]

  return (
    <div className="absolute left-6 right-6 top-24 rounded-md border border-gray-700 bg-gray-900 p-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex items-center justify-between">
            <Button
              variant={'ghost'}
              size={'icon'}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClose()
              }}
              disabled={loading}
            >
              <Cross1Icon width={20} height={20} />
            </Button>
            <span>{title}</span>
            <div className="h-9 w-9" />
          </div>
          <div className="space-y-4">
            <ChartContainer config={{ protein: {}, carbs: {}, fat: {} }} className="h-[150px] w-full">
              <PieChart>
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Pie data={chartData} dataKey="value" label={false} nameKey="macros" innerRadius={50} outerRadius={65}>
                  <Label
                    content={({ viewBox }) => {
                      if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                        return (
                          <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                            <tspan x={viewBox.cx} y={viewBox.cy || 0} className="fill-foreground text-xl font-bold">
                              {totalCalories}
                            </tspan>
                            <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 15} className="fill-muted-foreground">
                              Calories
                            </tspan>
                          </text>
                        )
                      }
                    }}
                  />
                </Pie>
              </PieChart>
            </ChartContainer>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="w-30"
                      type="text"
                      onFocus={(e) => e.target.select()}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="protein"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                  <FormLabel className="flex items-center space-x-2">
                    <div className="h-6 w-1 rounded-md bg-blue-300" />
                    <div>Protein (g)</div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="w-28"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="carbs"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                  <FormLabel className="flex items-center space-x-2">
                    <div className="h-6 w-1 rounded-md bg-orange-300" />
                    <div>Carbs (g)</div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="w-28"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="fat"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between space-x-4 space-y-0">
                  <FormLabel className="flex items-center space-x-2">
                    <div className="h-6 w-1 rounded-md bg-green-300" />
                    <div>Fat (g)</div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="w-28"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end space-x-2">
              {macroTarget && onDeleteMacroTarget && (
                <Button
                  variant={'outline'}
                  size={'icon'}
                  onClick={() => onDeleteMacroTarget(macroTarget.id)}
                  disabled={!form.formState.isValid || loading}
                >
                  <TrashIcon width={20} height={20} className="text-red-700" />
                </Button>
              )}
              <Button type="submit" variant={'outline'} disabled={!form.formState.isValid || loading}>
                {ctaLabel}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default MacroTargetsPage
