import { Button } from '@/components/ui/button'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export function ErrorBoundary() {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops!</h1>
        {JSON.stringify(error, null, 2)}
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    )
  } else {
    return (
      <div>
        <h1>Oops</h1>
        <p>{JSON.stringify(error, null, 2)}</p>
        <Button
          onClick={() => {
            localStorage.removeItem('userAttributes')
            window.location.reload()
          }}
          variant={'outline'}
        >
          Reset local data
        </Button>
      </div>
    )
  }
}
