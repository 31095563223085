import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import type { Schema } from '../../amplify/data/resource'
import { generateClient } from 'aws-amplify/data'
import { IS_DEV } from './environment'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const client = generateClient<Schema>()

export function isDebug() {
  const debug = localStorage.getItem('debugMode') === 'true'
  return IS_DEV || debug
}

export function debugLog(message?: unknown, ...rest: unknown[]) {
  if (isDebug()) {
    console.log(message, ...rest)
  }
}
