import { client } from '@/lib/utils'
import { startOfDay, endOfDay } from 'date-fns'
import { sortBy } from 'lodash'

export type Meal = {
  readonly id: string
  readonly name: string
  readonly enabled: boolean
  readonly displayOrder: number
  readonly owner: string
}

export type Unit = {
  readonly id: string
  readonly label: string
  readonly name: string
}

export type Food = {
  readonly id: string
  readonly name: string
  readonly searchName: string
  readonly brand: string | null
  readonly volume: number
  readonly calories: number
  readonly carbs: number
  readonly fat: number
  readonly protein: number
  readonly unit: Unit
}

export type UserLogForDate = {
  readonly id: string
  readonly volume: number
  readonly meal: {
    readonly id: string
  }
  readonly food: Food
}

export type CaloriePlanner = {
  readonly id: string
  readonly macroTargetId?: string | null
  readonly dayOfTheWeek: string
  readonly calories: number
  readonly protein: number
  readonly carbs: number
  readonly fat: number
}

export const getUserMeals = async (currentUserId: string): Promise<Meal[]> => {
  const { data } = await client.models.Meal.list({
    selectionSet: ['id', 'name', 'enabled', 'displayOrder', 'owner'],
    filter: {
      owner: { eq: currentUserId }
      // enabled: { eq: true }
    }
  })

  return sortBy(data, 'displayOrder')
}

export const getUserLogForDate = async (date: string): Promise<UserLogForDate[]> => {
  const { data } = await client.models.Log.list({
    selectionSet: [
      'id',
      'volume',
      'meal.id',
      'food.id',
      'food.name',
      'food.searchName',
      'food.brand',
      'food.volume',
      'food.calories',
      'food.carbs',
      'food.fat',
      'food.protein',
      'food.unit.id',
      'food.unit.label',
      'food.unit.name'
    ],
    filter: {
      date: {
        gt: startOfDay(date).toISOString(),
        lt: endOfDay(date).toISOString()
      }
    }
  })

  return data
}

export const getMyUserFoods = async (currentUserId: string): Promise<Food[]> => {
  const { data } = await client.models.Food.list({
    selectionSet: [
      'id',
      'name',
      'searchName',
      'brand',
      'type',
      'volume',
      'calories',
      'carbs',
      'fat',
      'protein',
      'unit.id',
      'unit.label',
      'unit.name'
    ],
    filter: { owners: { contains: currentUserId } }
  })

  console.log(data)

  return data
}

export const createNewUserFood = async ({ food, currentUserId }: { food: Food; currentUserId: string }) => {
  const { name, searchName, brand, calories, protein, carbs, fat, volume, unit } = food
  const { data } = await client.models.Food.create({
    name,
    searchName,
    brand,
    type: 'User',
    calories,
    protein,
    carbs,
    fat,
    volume,
    owners: [currentUserId],
    unitId: unit.id
  })

  return data
}

export const getUnits = async () => {
  const { data } = await client.models.Unit.list({
    selectionSet: ['id', 'name', 'label']
  })
  return data
}

export type MacroTarget = {
  readonly id: string
  readonly name: string
  readonly calories: number
  readonly protein: number
  readonly carbs: number
  readonly fat: number
}

export const createMacroTarget = async (payload: MacroTarget) => {
  const { data } = await client.models.MacroTarget.create(payload)
  return data
}

export const updateMacroTarget = async (payload: MacroTarget) => {
  const { data } = await client.models.MacroTarget.update(payload)
  return data
}

export const getUserMacroTargets = async (): Promise<MacroTarget[]> => {
  const { data } = await client.models.MacroTarget.list({
    selectionSet: ['id', 'name', 'calories', 'protein', 'carbs', 'fat']
  })
  return data
}

export const deleteUserMacroTarget = async (id: string): Promise<MacroTarget | null> => {
  const { data, errors } = await client.models.MacroTarget.delete({
    id
  })
  if (errors) console.error(errors)

  return data
}

export const getUserCaloriePlanner = async (): Promise<CaloriePlanner[]> => {
  const { data } = await client.models.CaloriePlanner.list()
  return data
}

export const createUserCaloriePlanner = async (payload: Omit<CaloriePlanner, 'id'>[]): Promise<CaloriePlanner[]> => {
  const promises = payload.map((entry) => {
    const { macroTargetId, dayOfTheWeek, calories, protein, carbs, fat } = entry
    return client.models.CaloriePlanner.create({
      macroTargetId,
      dayOfTheWeek,
      calories,
      protein,
      carbs,
      fat
    })
  })
  const results = await Promise.all(promises)

  return results
    .filter((result): result is { data: NonNullable<typeof result.data> } => {
      if (result.errors) console.error(result.errors)
      return result.data !== null
    })
    .map(({ data: { id, macroTargetId, dayOfTheWeek, calories, protein, carbs, fat } }) => ({
      id,
      macroTargetId,
      dayOfTheWeek,
      calories,
      protein,
      carbs,
      fat
    }))
}

export const updateUserCaloriePlanner = async ({
  id,
  dayOfTheWeek,
  macroTargetId,
  calories,
  protein,
  carbs,
  fat
}: CaloriePlanner): Promise<CaloriePlanner> => {
  const { data, errors } = await client.models.CaloriePlanner.update({
    id,
    dayOfTheWeek,
    macroTargetId,
    calories,
    protein,
    carbs,
    fat
  })
  if (data === null) {
    console.error(errors)
    throw new Error(JSON.stringify(errors, null, 2))
  }
  return data
}

export const searchFatsecret = async (searchTerm: string) => {
  const { data, errors } = await client.queries.FatsecretSearch({
    searchTerm
  })

  if (errors) console.error(errors)
  return data
}
