import { MainNavigation } from '@/components/MainNavigation'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious
} from '@/components/ui/pagination'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { format, addDays, subDays, isYesterday, isTomorrow, isToday, formatISO } from 'date-fns'
import { PlusCircledIcon } from '@radix-ui/react-icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { calculateMacrosPerVolume } from '@/helpers/calculateMacrosPerVolume'
import { getUserLogForDate, Food, UserLogForDate } from '@/services/aws'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { PageSpinner } from '@/components/ui/pageSpinner'
import { debugLog } from '@/lib/utils'
import { LogFoodModal } from '@/components/LogFoodModal'

const formatDate = (date: string) => {
  if (isToday(date)) {
    return `Today, ${format(date, 'MMM d')}`
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, 'MMM d')}`
  } else if (isTomorrow(date)) {
    return `Tomorrow, ${format(date, 'MMM d')}`
  } else {
    return format(date, 'EEE, MMM d')
  }
}

const HomePage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const [userLog, setUserLog] = useState<UserLogForDate[]>([])
  const [selectedFood, setSelectedFood] = useState<{
    logId: string
    food: Food
    volume: number
    mealId: string
  } | null>(null)

  const { date } = useParams()

  if (!date) {
    throw new Error('no date')
  }

  debugLog('hey homepage', `date:${date}, loading:${loading}`)

  const fetchUserLog = useCallback(async () => {
    setLoading(true)
    await getUserLogForDate(date)
      .then((userLog) => {
        setUserLog(userLog)
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }, [date])

  const chartData = useMemo(() => {
    const totalMacros = userLog
      .map((log) => calculateMacrosPerVolume({ food: log.food, volume: log.volume }))
      .reduce(
        (acc, food) => {
          acc.calories += food.calories
          acc.protein += food.protein
          acc.carbs += food.carbs
          acc.fat += food.fat
          return acc
        },
        { calories: 0, protein: 0, carbs: 0, fat: 0 }
      )

    const todayTargets = currentUser?.userCaloriePlanner.find((cp) => cp.dayOfTheWeek === format(date, 'EEEE'))

    return [
      {
        label: 'Cals',
        currentValue: Number(totalMacros.calories.toFixed(1)),
        maxValue: todayTargets ? todayTargets.calories : 0
      },
      {
        label: 'Protein',
        currentValue: Number(totalMacros.protein.toFixed(1)),
        maxValue: todayTargets ? todayTargets.protein : 0,
        color: 'bg-blue-300'
      },
      {
        label: 'Carbs',
        currentValue: Number(totalMacros.carbs.toFixed(1)),
        maxValue: todayTargets ? todayTargets.carbs : 0,
        color: 'bg-orange-300'
      },
      {
        label: 'Fat',
        currentValue: Number(totalMacros.fat.toFixed(1)),
        maxValue: todayTargets ? todayTargets.fat : 0,
        color: 'bg-green-300'
      }
    ]
  }, [currentUser?.userCaloriePlanner, date, userLog])

  useEffect(() => {
    fetchUserLog()
  }, [date, fetchUserLog])

  if (!currentUser) return <PageSpinner />

  return (
    <>
      <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}>
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                onClick={() => navigate(`/${formatISO(subDays(date, 1), { representation: 'date' })}`)}
                hideLabel
                className="p-3"
              />
            </PaginationItem>
            <PaginationItem
              className="mx-4"
              onClick={() => navigate(`/${formatISO(new Date(), { representation: 'date' })}`)}
            >
              {formatDate(date)} - {format(date, 'EEEE')}
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                onClick={() => navigate(`/${formatISO(addDays(date, 1), { representation: 'date' })}`)}
                hideLabel
                className="p-3"
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
      <div className="relative my-20 h-[calc(100vh-160px)] overflow-y-auto bg-gray-900">
        <div className={`px-6`}>
          {loading && <PageSpinner />}

          <div>
            <div className="mb-6 flex justify-between rounded-md border border-b-gray-800 p-6">
              <div className="flex space-x-3">
                {chartData.map((m) => {
                  if (m.label === 'Cals') return
                  const height = (m.currentValue / m.maxValue) * 100
                  return (
                    <div className="flex-coll flex w-3 items-end rounded-md bg-slate-800" key={`chart-${m.label}`}>
                      <div
                        className={`w-full rounded-md ${height > 100 ? 'bg-red-500' : m.color}`}
                        style={{ height: `${height > 100 ? 100 : height}%` }}
                      />
                    </div>
                  )
                })}
              </div>
              <div className="space-y-2 text-sm">
                {chartData.map((m) => (
                  <div className="flex justify-end space-x-4" key={m.label}>
                    <span className="flex w-16 space-x-1">
                      {m.color && <span className={`w-1 rounded-sm ${m.color}`} />}
                      <span>{m.label}</span>
                    </span>
                    <span className={`w-12 text-end ${m.currentValue > m.maxValue ? 'text-red-500' : ''}`}>
                      {m.currentValue}
                    </span>
                    <span className="w-12 text-end text-gray-400">{m.maxValue}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="divide-y-2">
              {currentUser.userMeals
                .filter((meal) => meal.enabled)
                .map((meal) => {
                  const foodPerMeal = userLog.filter((l) => l.meal.id === meal.id)
                  const mealTotalMacros = foodPerMeal.reduce(
                    (acc, log) => {
                      const calculatedMacros = calculateMacrosPerVolume({ food: log.food, volume: log.volume })
                      acc.calories += calculatedMacros.calories
                      acc.protein += calculatedMacros.protein
                      acc.carbs += calculatedMacros.carbs
                      acc.fat += calculatedMacros.fat
                      return acc
                    },
                    { calories: 0, protein: 0, carbs: 0, fat: 0 }
                  )
                  return (
                    <div key={meal.id}>
                      <Link
                        to={`/${date}/m/${meal.id}`}
                        className="flex w-full items-center justify-between py-4 text-left"
                      >
                        <span className="flex flex-col">
                          <span>{meal.name}</span>
                          <span className="text-start text-sm text-gray-400">
                            {`${mealTotalMacros.calories.toFixed(1)} Cal, ${mealTotalMacros.protein.toFixed(1)}p, ${mealTotalMacros.carbs.toFixed(1)}c, ${mealTotalMacros.fat.toFixed(1)}f`}
                          </span>
                        </span>

                        <PlusCircledIcon width={20} height={20} />
                      </Link>

                      {foodPerMeal.length > 0 && (
                        <div className="mb-4 w-full rounded-md border p-2">
                          {foodPerMeal.map(({ id, food, volume }) => {
                            const calculatedMacros = calculateMacrosPerVolume({ food: food, volume })
                            return (
                              <div
                                key={id}
                                className="border-0 border-t border-gray-800 py-3 first:border-t-0 first:pt-0 last:pb-0"
                                onClick={() => setSelectedFood({ logId: id, food: food, volume, mealId: meal.id })}
                              >
                                <div>{calculatedMacros.name}</div>
                                <div className="flex space-x-2 text-start text-sm text-gray-400">
                                  <span>{`${calculatedMacros.volume} ${calculatedMacros.unit.label}`}</span>
                                  <span>·</span>
                                  <span>{calculatedMacros.calories} Cal,</span>
                                  <span>{calculatedMacros.protein}p,</span>
                                  <span>{calculatedMacros.carbs}c,</span>
                                  <span>{calculatedMacros.fat}f,</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>

      {selectedFood && (
        <LogFoodModal
          food={selectedFood.food}
          mealId={selectedFood.mealId}
          volume={selectedFood.volume}
          logId={selectedFood.logId}
          onClose={(refetch) => {
            setSelectedFood(null)
            refetch && fetchUserLog()
          }}
          action="update"
        />
      )}

      <MainNavigation />
    </>
  )
}

export default HomePage
