import { MainNavigation } from '@/components/MainNavigation'
import { Button } from '@/components/ui/button'
import { PATH_NAME } from '@/routes/paths'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ArrowLeftIcon,
  Cross1Icon,
  DotsHorizontalIcon,
  LightningBoltIcon,
  MagnifyingGlassIcon,
  Pencil2Icon,
  PersonIcon
} from '@radix-ui/react-icons'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer'
import { debugLog } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { PageSpinner } from '@/components/ui/pageSpinner'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { LogFoodModal } from '@/components/LogFoodModal'
import { Input } from '@/components/ui/input'
import _ from 'lodash'
import { getMyUserFoods, searchFatsecret, Food } from '@/services/aws'

const MealPage: React.FC = () => {
  const [isSearchActive, setSearchActive] = useState(false)
  const [selectedFood, setSelectedFood] = useState<Food | null>(null)
  const [loading, setLoading] = useState(false)
  const [myFoods, setMyFoods] = useState<Food[]>([])
  const [searchResults, setSearchResults] = useState<Food[] | null>(null)
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    // searchFatsecret('eggs')
  }, [])

  const search = _.debounce(async (value: string) => {
    // const { data } = await client.models.UserFood.list({
    //   filter: { searchName: { contains: value.toLocaleLowerCase() } }
    // })

    // const payload = data.map((data) => {
    //   const { id, brand, name, searchName, volume, calories, protein, carbs, fat } = data
    //   return {
    //     id,
    //     brand,
    //     name,
    //     searchName,
    //     volume,
    //     calories,
    //     protein,
    //     carbs,
    //     fat,
    //     unit: {
    //       id: '',
    //       label: '',
    //       name: ''
    //     }
    //   }
    // })

    const data = await searchFatsecret(value)
    console.log(data?.foods)
    if (data?.foods) {
      const payload = data.foods.map((food) => {
        return {
          id: food.id,
          brand: food.brand || null,
          name: food.name,
          searchName: food.name.toLowerCase(),
          volume: 1,
          calories: food.calories,
          protein: food.calories,
          carbs: food.carbs,
          fat: food.fat,
          unit: {
            id: 'g',
            label: 'grams',
            name: 'g'
          }
        }
      })
      setSearchResults(payload)
    }
  }, 500)

  const { mealId } = useParams()

  if (typeof mealId !== 'string' || !currentUser) {
    throw new Error(`${mealId} is ${typeof mealId} or missing currentUser`)
  }

  debugLog('hey mealpage', `mealId:${mealId}, loading:${loading}`)

  const mealName = currentUser.userMeals.filter((meal) => meal.id === mealId)[0].name

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await getMyUserFoods(currentUser.uId)
        .then((myFoods) => {
          setMyFoods(myFoods)
          setLoading(false)
        })
        .catch((err) => console.error(err))
    }

    fetch()
  }, [mealId, currentUser])

  console.log(myFoods, searchResults)

  return (
    <>
      <Drawer>
        <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}>
          <div className="flex items-center justify-between">
            <Button variant={'ghost'} size={'icon'} onClick={() => navigate(-1)}>
              <ArrowLeftIcon width={20} height={20} />
            </Button>
            <span>{mealName}</span>
            <DrawerTrigger className="flex h-9 w-9 items-center justify-center">
              <DotsHorizontalIcon width={20} height={20} />
            </DrawerTrigger>
          </div>
        </div>
        <div className="my-20 mb-20 h-[calc(100vh-160px)] overflow-y-auto bg-gray-900">
          <div className={`px-6 pt-6`}>
            <div className="relative mb-4 flex items-center">
              <MagnifyingGlassIcon className="absolute left-2 top-2" width={20} height={20} />
              <Input
                placeholder="Search"
                className="pl-8"
                onChange={(e) => search(e.currentTarget.value)}
                onFocus={() => setSearchActive(true)}
              />
              {isSearchActive && (
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  onClick={() => {
                    setSearchResults(null)
                    setSearchActive(false)
                  }}
                  className="ml-2"
                >
                  <Cross1Icon width={20} height={20} />
                </Button>
              )}
            </div>

            {isSearchActive ? (
              <div className="grid grid-cols-1 divide-y">
                {searchResults ? (
                  searchResults.map((food) => <FoodItem food={food} onClick={(food) => setSelectedFood(food)} />)
                ) : (
                  <span>Search by bood or brand name</span>
                )}
              </div>
            ) : (
              <>
                <div className="flex space-x-4 py-4">
                  <h3>My Foods</h3>
                  <h3 className="text-gray-400">Recipes</h3>
                </div>
                <div>
                  {loading && <PageSpinner />}
                  {!loading && (
                    <div className="grid grid-cols-1 divide-y">
                      {myFoods.map((food) => (
                        <FoodItem food={food} onClick={(food) => setSelectedFood(food)} />
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle hidden>Are you absolutely sure?</DrawerTitle>
            <DrawerDescription className="flex flex-col space-y-4 pb-8 text-base text-white">
              <DrawerClose className="flex items-center space-x-2">
                <LightningBoltIcon />
                <span>Quick add</span>
              </DrawerClose>
              <DrawerClose className="flex items-center space-x-2" onClick={() => navigate(PATH_NAME.NEW_FOOD)}>
                <Pencil2Icon />
                <span>Create food</span>
              </DrawerClose>
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>

      {selectedFood && (
        <LogFoodModal food={selectedFood} mealId={mealId} onClose={() => setSelectedFood(null)} action="create" />
      )}

      <MainNavigation />
    </>
  )
}

function FoodItem({ food, onClick }: { food: Food; onClick: (food: Food) => void }) {
  return (
    <div key={food.id} className="py-4" onClick={() => onClick(food)}>
      <div>
        {food.name} {food.brand && `(${food.brand})`}
      </div>
      <div className="flex items-center space-x-2 text-sm text-gray-400">
        <span>
          <PersonIcon width={16} height={16} />
        </span>
        <span>
          {food.volume} {food.unit.label}
        </span>
        <span>·</span>
        <span>{food.calories} Cal,</span>
        <span>{food.protein}p,</span>
        <span>{food.carbs}c,</span>
        <span>{food.fat}f</span>
      </div>
    </div>
  )
}

export default MealPage
