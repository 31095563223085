export const PATH_NAME = {
  HOME: '/:date',
  LOGIN: '/login',
  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_CUSTOMIZE_MEALS: '/settings/customize-meals',
  SETTINGS_MACRO_TARGETS: '/settings/macro-targets',
  SETTINGS_CALORIE_PLANNER: '/settings/calorie-planner',
  MEAL: '/:date/m/:mealId',
  NEW_FOOD: '/new-food',
  NEW_RECIPE: '/new-recipe',
  ADMIN_DASHBOARD: '/admin',
  TERMS: '/terms',
  PRIVACY: '/privacy'
}
