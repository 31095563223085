import { useContext } from 'react'
import { AuthContext } from '@/components/AuthContextProvider'
import { MacroTarget } from '@/services/aws'

const useSyncUserMacroTargets = () => {
  const authContext = useContext(AuthContext)

  const syncUserMacroTargets = (macroTarget: MacroTarget, action: 'create' | 'update' | 'delete') => {
    if (authContext) {
      let updatedMacroTargets: MacroTarget[] = []
      if (action === 'create') {
        updatedMacroTargets = [...authContext.userMacroTargets, macroTarget]
      } else if (action === 'update') {
        updatedMacroTargets = authContext.userMacroTargets.map((target) =>
          target.id === macroTarget.id ? macroTarget : target
        )
      } else if (action === 'delete') {
        updatedMacroTargets = authContext.userMacroTargets.filter((target) => target.id !== macroTarget.id)
      }
      authContext.userMacroTargets = updatedMacroTargets

      const updatedContext = { ...authContext, userMacroTargets: updatedMacroTargets }
      localStorage.setItem('userAttributes', JSON.stringify(updatedContext))
      console.log(updatedContext)
    }
  }

  return syncUserMacroTargets
}

export default useSyncUserMacroTargets
