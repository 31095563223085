import { MainNavigation } from '@/components/MainNavigation'
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { bg } from 'date-fns/locale'

import { useNavigate } from 'react-router-dom'
import { PATH_NAME } from '@/routes/paths'
import { deleteUser } from 'aws-amplify/auth'
import { Button } from '@/components/ui/button'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { format } from 'date-fns'

const deleteAccount = async () => {
  await deleteUser() // Implement delete account functionality here
}

const ProfilePage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  return (
    <>
      {/* <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div> */}
      <div className="mb-20 h-[calc(100vh-80px)] overflow-y-auto bg-gray-900">
        <div className={`px-6 pt-6`}>
          <div className="mb-8 flex items-center space-x-4 align-middle">
            <ArrowLeftIcon width={20} height={20} onClick={() => navigate(PATH_NAME.SETTINGS)} />
            <h1 className="text-xl font-bold">Profile</h1>
          </div>

          {currentUser && (
            <div className="mb-6 space-y-4">
              <div className="flex justify-between">
                <span>Name</span>
                <span>
                  {currentUser.firstName} {currentUser.lastName}
                </span>
              </div>

              <div className="flex justify-between">
                <span>Email</span>
                <span>{currentUser.email}</span>
              </div>

              <div className="flex justify-between">
                <span>Birthdate</span>
                <span>{format(currentUser.birthdate, 'dd.MM.yyyy', { locale: bg })}</span>
              </div>
            </div>
          )}

          <Button variant="destructive" onClick={deleteAccount}>
            Delete Account
          </Button>
        </div>
      </div>
      <MainNavigation />
    </>
  )
}

export default ProfilePage
