import AdminSidebar from '@/components/admin/Sidebar'

const AdminDashboardPage: React.FC = () => {
  return (
    <div>
      <AdminSidebar />
    </div>
  )
}

export default AdminDashboardPage
