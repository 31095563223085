import { useState } from 'react'
import { createNewUserFood, Food } from '@/services/aws'
import { useCurrentUser } from './useCurrentUser'

const useCreateMyUserFood = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const currentUser = useCurrentUser()

  const createNewFood = async (food: Food) => {
    if (currentUser) {
      try {
        setLoading(true)
        await createNewUserFood({ food, currentUserId: currentUser.uId })
        // Handle the data if needed
      } catch (err) {
        console.error(err)
        setError(true)
      } finally {
        setLoading(false)
      }
    }
  }

  return { createNewFood, loading, error }
}

export default useCreateMyUserFood
