import { useContext } from 'react'
import { AuthContext } from '@/components/AuthContextProvider'
import { CaloriePlanner } from '@/services/aws'

const useSyncUserCaloriePlanner = () => {
  const authContext = useContext(AuthContext)

  const syncUserCaloriePlanner = (caloriePlanner: CaloriePlanner[]) => {
    if (authContext) {
      const updatedCaloriePlanner: CaloriePlanner[] = [...authContext.userCaloriePlanner]

      caloriePlanner.forEach((newRecord) => {
        const index = updatedCaloriePlanner.findIndex((record) => record.id === newRecord.id)
        if (index !== -1) {
          // Update existing record
          updatedCaloriePlanner[index] = newRecord
        } else {
          // Add new record
          updatedCaloriePlanner.push(newRecord)
        }
      })

      authContext.userCaloriePlanner = updatedCaloriePlanner

      const updatedContext = { ...authContext, userCaloriePlanner: updatedCaloriePlanner }
      localStorage.setItem('userAttributes', JSON.stringify(updatedContext))
      console.log(updatedContext)
    }
  }

  return syncUserCaloriePlanner
}

export default useSyncUserCaloriePlanner
