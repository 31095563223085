import { Food } from '@/services/aws'

export const calculateMacrosPerVolume = ({ food, volume }: { food: Food; volume: number }): Food => {
  const factor = volume / food.volume
  const macros = {
    volume,
    calories: Number((food.calories * factor).toFixed(1)),
    protein: Number((food.protein * factor).toFixed(1)),
    carbs: Number((food.carbs * factor).toFixed(1)),
    fat: Number((food.fat * factor).toFixed(1))
  }

  return {
    ...food,
    ...macros
  }
}
