import '@aws-amplify/ui-react/styles.css'
import * as Sentry from '@sentry/browser'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import outputs from '../amplify_outputs.json'
import { AuthContextProvider } from './components/AuthContextProvider.tsx'
import './index.css'
import { App } from './App.tsx'

Amplify.configure(outputs)

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.COMMIT_REF,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
)
